import React from 'react';
// import PropTypes from 'prop-types';
// import Notification  from 'cccisd-notification';
import Form from './form.js';
import Loader from 'cccisd-loader';
import Axios from 'cccisd-axios';
import Notification from 'cccisd-notification';
const Boilerplate = window.cccisd.boilerplate;

export default class Component extends React.Component {
    static propTypes = {
        // params: PropTypes.object,
    };

    state = {
        loading: false,
        data: null,
    };

    componentDidMount = async () => {
        // getDataFrom Query
        // passData down to Form
        const response = await Axios.get(Boilerplate.route('ccu.get.data'));

        if (response) {
            const classroomStructure = response.data.data.classroomStructure;

            this.setState({ data: classroomStructure ? classroomStructure.data : [] });
        }
    };

    onSubmit = async values => {
        let arr = Object.keys(values).map((key, i) => {
            return { name: key, value: values[key] };
        });

        const classroomStructureData = this.extractData(arr);

        const response = await Axios.post(Boilerplate.route('ccu.set.data'), {
            data_key: 'classroomStructure',
            data: classroomStructureData,
        });

        if (response.data.success) {
            Notification({
                message: 'Classroom structure data saved.',
                duration: 3000,
            });
        }
    };

    extractData = arr => {
        const physicalLayoutData = arr.filter(item => item.name.includes('physicalLayout'));
        const physicalLayout = this.processData(physicalLayoutData);

        const classroomRulesData = arr.filter(item => item.name.includes('classroomRules'));
        const classroomRules = this.processData(classroomRulesData);
        const classroomRoutinesData = arr.filter(item => item.name.includes('classroomRoutines'));
        const classroomRoutines = this.processData(classroomRoutinesData);
        const smoothTransitionsData = arr.filter(item => item.name.includes('smoothTransitions'));
        const smoothTransitions = this.processData(smoothTransitionsData);
        const otherData = arr.filter(item => item.name.includes('other'));
        const other = this.processData(otherData);

        return [
            { physicalLayout },
            { classroomRules },
            { classroomRoutines },
            { smoothTransitions },
            { other },
        ];
    };

    processData = array => {
        const mappedData = array.map(item => {
            if (item.value) {
                const cleanValue = item.value.replace(/^\s*\n/gm, '');

                return cleanValue.split('\n').filter(value => value !== '');
            }
            return [];
        });

        return mappedData;
    };

    render() {
        return (
            <Loader loading={this.state.loading} removeChildren>
                {this.state.data && <Form onSubmit={this.onSubmit} data={this.state.data} />}
            </Loader>
        );
    }
}
