import React from 'react';
import { Formik, Form, Field, CccisdTextarea } from 'cccisd-formik';
import PropTypes from 'prop-types';
import style from './style.css';
import Save from 'cccisd-icons/folder-upload2';
import Triangle from 'cccisd-icons/triangle2';
export default class Component extends React.Component {
    static propTypes = {
        onSubmit: PropTypes.func,
        data: PropTypes.object,
    };

    state = {
        data: this.props.data.length > 0 ? JSON.parse(this.props.data) : [],
        show: {
            studentsInteractions: false,
            noncontingentAttentionUse: false,
            disruptiveBehavior: false,
            studentAccuracy: false,
        },
    };

    componentDidMount = () => {};

    getInitialValues = (question, index) => {
        if (this.state.data) {
            const data = this.state.data;
            const entry = data.find(item => {
                if (question in item) {
                    return item;
                }
                return null;
            });

            if (entry) {
                const values = entry[question];
                if (values[index].length > 0) {
                    let string = '';
                    values[index].forEach(item => {
                        string = string + item + '\n';
                    });

                    return string;
                }
            }
            return '';
        }
    };

    render() {
        return (
            <div className={style.classroomStructure}>
                <div>
                    Enter and edit the list of descriptors for each score for each scale item.
                    Separate descriptors by using a new line.
                </div>
                <br />
                <Formik
                    initialValues={{
                        noncontingentAttentionUse0: this.getInitialValues(
                            'noncontingentAttentionUse',
                            0
                        ),
                        noncontingentAttentionUse1: this.getInitialValues(
                            'noncontingentAttentionUse',
                            1
                        ),
                        noncontingentAttentionUse2: this.getInitialValues(
                            'noncontingentAttentionUse',
                            2
                        ),
                        noncontingentAttentionUse3: this.getInitialValues(
                            'noncontingentAttentionUse',
                            3
                        ),
                        noncontingentAttentionUse4: this.getInitialValues(
                            'noncontingentAttentionUse',
                            4
                        ),
                        noncontingentAttentionUse5: this.getInitialValues(
                            'noncontingentAttentionUse',
                            5
                        ),
                        studentsInteractions0: this.getInitialValues('studentsInteractions', 0),
                        studentsInteractions1: this.getInitialValues('studentsInteractions', 1),
                        studentsInteractions2: this.getInitialValues('studentsInteractions', 2),
                        studentsInteractions3: this.getInitialValues('studentsInteractions', 3),
                        studentsInteractions4: this.getInitialValues('studentsInteractions', 4),
                        studentsInteractions5: this.getInitialValues('studentsInteractions', 5),
                        disruptiveBehavior0: this.getInitialValues('disruptiveBehavior', 0),
                        disruptiveBehavior1: this.getInitialValues('disruptiveBehavior', 1),
                        disruptiveBehavior2: this.getInitialValues('disruptiveBehavior', 2),
                        disruptiveBehavior3: this.getInitialValues('disruptiveBehavior', 3),
                        disruptiveBehavior4: this.getInitialValues('disruptiveBehavior', 4),
                        disruptiveBehavior5: this.getInitialValues('disruptiveBehavior', 5),
                        other0: this.getInitialValues('other', 0),
                        other1: this.getInitialValues('other', 1),
                        other2: this.getInitialValues('other', 2),
                        other3: this.getInitialValues('other', 3),
                        other4: this.getInitialValues('other', 4),
                        other5: this.getInitialValues('other', 5),
                    }}
                    onSubmit={this.props.onSubmit}
                    render={() => (
                        <Form>
                            <div className={style.sectionWrapper}>
                                <span
                                    className={style.dropDownTop}
                                    onClick={() => {
                                        this.setState({
                                            show: {
                                                noncontingentAttentionUse: !this.state.show
                                                    .noncontingentAttentionUse,
                                            },
                                        });
                                    }}
                                >
                                    <h4>Use of Noncontingent Attention</h4>{' '}
                                    <span
                                        className={
                                            this.state.show.noncontingentAttentionUse
                                                ? style.open
                                                : style.closed
                                        }
                                    >
                                        <Triangle />
                                    </span>
                                </span>
                                {this.state.show.noncontingentAttentionUse && (
                                    <div>
                                        <Field
                                            name="noncontingentAttentionUse0"
                                            component={CccisdTextarea}
                                            label="Score: 0"
                                        />
                                        <Field
                                            name="noncontingentAttentionUse1"
                                            component={CccisdTextarea}
                                            label="Score: 1"
                                        />
                                        <Field
                                            name="noncontingentAttentionUse2"
                                            component={CccisdTextarea}
                                            label="Score: 2"
                                        />
                                        <Field
                                            name="noncontingentAttentionUse3"
                                            component={CccisdTextarea}
                                            label="Score: 3"
                                        />
                                        <Field
                                            name="noncontingentAttentionUse4"
                                            component={CccisdTextarea}
                                            label="Score: 4"
                                        />
                                        <Field
                                            name="noncontingentAttentionUse5"
                                            component={CccisdTextarea}
                                            label="Score: 5"
                                        />{' '}
                                    </div>
                                )}
                            </div>
                            <div className={style.sectionWrapper}>
                                <span
                                    className={style.dropDownTop}
                                    onClick={() => {
                                        this.setState({
                                            show: {
                                                studentsInteractions: !this.state.show
                                                    .studentsInteractions,
                                            },
                                        });
                                    }}
                                >
                                    <h4>Interactions with Students</h4>{' '}
                                    <span
                                        className={
                                            this.state.show.studentsInteractions
                                                ? style.open
                                                : style.closed
                                        }
                                    >
                                        <Triangle />
                                    </span>
                                </span>
                                {this.state.show.studentsInteractions && (
                                    <div>
                                        <Field
                                            name="studentsInteractions0"
                                            component={CccisdTextarea}
                                            label="Score: 0"
                                        />
                                        <Field
                                            name="studentsInteractions1"
                                            component={CccisdTextarea}
                                            label="Score: 1"
                                        />
                                        <Field
                                            name="studentsInteractions2"
                                            component={CccisdTextarea}
                                            label="Score: 2"
                                        />
                                        <Field
                                            name="studentsInteractions3"
                                            component={CccisdTextarea}
                                            label="Score: 3"
                                        />
                                        <Field
                                            name="studentsInteractions4"
                                            component={CccisdTextarea}
                                            label="Score: 4"
                                        />
                                        <Field
                                            name="studentsInteractions5"
                                            component={CccisdTextarea}
                                            label="Score: 5"
                                        />{' '}
                                    </div>
                                )}
                            </div>
                            <div className={style.sectionWrapper}>
                                <span
                                    className={style.dropDownTop}
                                    onClick={() => {
                                        this.setState({
                                            show: {
                                                disruptiveBehavior: !this.state.show
                                                    .disruptiveBehavior,
                                            },
                                        });
                                    }}
                                >
                                    <h4>Level of Disruptive Behavior</h4>{' '}
                                    <span
                                        className={
                                            this.state.show.disruptiveBehavior
                                                ? style.open
                                                : style.closed
                                        }
                                    >
                                        <Triangle />
                                    </span>
                                </span>
                                {this.state.show.disruptiveBehavior && (
                                    <div>
                                        <Field
                                            name="disruptiveBehavior0"
                                            component={CccisdTextarea}
                                            label="Score: 0"
                                        />
                                        <Field
                                            name="disruptiveBehavior1"
                                            component={CccisdTextarea}
                                            label="Score: 1"
                                        />
                                        <Field
                                            name="disruptiveBehavior2"
                                            component={CccisdTextarea}
                                            label="Score: 2"
                                        />
                                        <Field
                                            name="disruptiveBehavior3"
                                            component={CccisdTextarea}
                                            label="Score: 3"
                                        />
                                        <Field
                                            name="disruptiveBehavior4"
                                            component={CccisdTextarea}
                                            label="Score: 4"
                                        />
                                        <Field
                                            name="disruptiveBehavior5"
                                            component={CccisdTextarea}
                                            label="Score: 5"
                                        />{' '}
                                    </div>
                                )}
                            </div>
                            <div className={style.sectionWrapper}>
                                <span
                                    className={style.dropDownTop}
                                    onClick={() => {
                                        this.setState({
                                            show: {
                                                other: !this.state.show.other,
                                            },
                                        });
                                    }}
                                >
                                    <h4>Other</h4>{' '}
                                    <span
                                        className={
                                            this.state.show.other ? style.open : style.closed
                                        }
                                    >
                                        <Triangle />
                                    </span>
                                </span>
                                {this.state.show.other && (
                                    <div>
                                        <Field
                                            name="other0"
                                            component={CccisdTextarea}
                                            label="Score: 0"
                                        />
                                        <Field
                                            name="other1"
                                            component={CccisdTextarea}
                                            label="Score: 1"
                                        />
                                        <Field
                                            name="other2"
                                            component={CccisdTextarea}
                                            label="Score: 2"
                                        />
                                        <Field
                                            name="other3"
                                            component={CccisdTextarea}
                                            label="Score: 3"
                                        />
                                        <Field
                                            name="other4"
                                            component={CccisdTextarea}
                                            label="Score: 4"
                                        />
                                        <Field
                                            name="other5"
                                            component={CccisdTextarea}
                                            label="Score: 5"
                                        />{' '}
                                    </div>
                                )}
                            </div>

                            <button
                                className={`btn btn-primary ${style.stickyButton}`}
                                type="submit"
                            >
                                <span style={{ marginRight: '5px' }}>
                                    <Save />
                                </span>
                                Save Values
                            </button>
                        </Form>
                    )}
                />
            </div>
        );
    }
}
