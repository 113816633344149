import React from 'react';
// import PropTypes from 'prop-types';
import Form from './form.js';
import Loader from 'cccisd-loader';
import Axios from 'cccisd-axios';
import Notification from 'cccisd-notification';
const Boilerplate = window.cccisd.boilerplate;

export default class Component extends React.Component {
    static propTypes = {
        // params: PropTypes.object,
    };

    state = {
        loading: false,
    };

    componentDidMount = async () => {
        const response = await Axios.get(Boilerplate.route('ccu.get.data'));

        if (response) {
            const teacherNudges = response.data.data.teacherNudges;

            this.setState({
                data: teacherNudges && teacherNudges.data ? teacherNudges.data : [],
            });
        }
    };

    onSubmit = async values => {
        let data = Object.values(values);
        data = data[0].replace(/(\r\n|\n|\r)/gm, '');

        const response = await Axios.post(Boilerplate.route('ccu.set.data'), {
            data_key: 'teacherNudges',
            data,
        });

        if (response.data.success) {
            Notification({
                message: 'Order of teacher nudges saved.',
                duration: 3000,
            });
        }
    };

    render() {
        return (
            <Loader loading={this.state.loading} removeChildren>
                {this.state.data && <Form onSubmit={this.onSubmit} data={this.state.data} />}
            </Loader>
        );
    }
}
