import React from 'react';
// import PropTypes from 'prop-types';
import Form from './form.js';
import Loader from 'cccisd-loader';
import Axios from 'cccisd-axios';
import Notification from 'cccisd-notification';
const Boilerplate = window.cccisd.boilerplate;

export default class Component extends React.Component {
    static propTypes = {
        // params: PropTypes.object,
    };

    state = {
        loading: false,
    };

    componentDidMount = async () => {
        // getDataFrom Query
        // passData down to Form
        const response = await Axios.get(Boilerplate.route('ccu.get.data'));

        if (response) {
            const instructionManagement = response.data.data.instructionManagement;

            this.setState({ data: instructionManagement ? instructionManagement.data : [] });
        }
    };

    onSubmit = async values => {
        let arr = Object.keys(values).map((key, i) => {
            return { name: key, value: values[key] };
        });

        const instructionManagementData = this.extractData(arr);

        const response = await Axios.post(Boilerplate.route('ccu.set.data'), {
            data_key: 'instructionManagement',
            data: instructionManagementData,
        });

        if (response.data.success) {
            Notification({
                message: 'Instruction management data saved.',
                duration: 3000,
            });
        }
    };

    extractData = arr => {
        const schedulePostedFollowedData = arr.filter(item =>
            item.name.includes('schedulePostedFollowed')
        );
        const schedulePostedFollowed = this.processData(schedulePostedFollowedData);
        const academicObjectivesData = arr.filter(item => item.name.includes('academicObjectives'));
        const academicObjectives = this.processData(academicObjectivesData);
        const pacingInstructionData = arr.filter(item => item.name.includes('pacingInstruction'));
        const pacingInstruction = this.processData(pacingInstructionData);
        const studentAccuracyData = arr.filter(item => item.name.includes('studentAccuracy'));
        const studentAccuracy = this.processData(studentAccuracyData);
        const studentEngagementData = arr.filter(item => item.name.includes('studentEngagement'));
        const studentEngagement = this.processData(studentEngagementData);
        const otherData = arr.filter(item => item.name.includes('other'));
        const other = this.processData(otherData);

        return [
            { schedulePostedFollowed },
            { academicObjectives },
            { pacingInstruction },
            { studentAccuracy },
            { studentEngagement },
            { other },
        ];
    };

    processData = array => {
        const mappedData = array.map(item => {
            if (item.value) {
                const cleanValue = item.value.replace(/^\s*\n/gm, '');

                return cleanValue.split('\n').filter(value => value !== '');
            }
            return [];
        });
        return mappedData;
    };

    render() {
        return (
            <Loader loading={this.state.loading} removeChildren>
                {this.state.data && <Form onSubmit={this.onSubmit} data={this.state.data} />}
            </Loader>
        );
    }
}
