import React from 'react';
// import PropTypes from 'prop-types';
import Form from './form.js';
import Loader from 'cccisd-loader';
import Axios from 'cccisd-axios';
import Notification from 'cccisd-notification';
const Boilerplate = window.cccisd.boilerplate;

export default class Component extends React.Component {
    static propTypes = {
        // params: PropTypes.object,
    };

    state = {
        loading: false,
    };

    componentDidMount = async () => {
        // getDataFrom Query
        // passData down to Form
        const response = await Axios.get(Boilerplate.route('ccu.get.data'));

        if (response) {
            const behaviorManagement = response.data.data.behaviorManagement;

            this.setState({ data: behaviorManagement ? behaviorManagement.data : [] });
        }
    };

    onSubmit = async values => {
        let arr = Object.keys(values).map((key, i) => {
            return { name: key, value: values[key] };
        });

        const behaviorManagementData = this.extractData(arr);

        const response = await Axios.post(Boilerplate.route('ccu.set.data'), {
            data_key: 'behaviorManagement',
            data: behaviorManagementData,
        });

        if (response.data.success) {
            Notification({
                message: 'Behavior management data saved.',
                duration: 3000,
            });
        }
    };

    extractData = arr => {
        const behavioralExpectationsData = arr.filter(item =>
            item.name.includes('behavioralExpectations')
        );
        const behavioralExpectations = this.processData(behavioralExpectationsData);
        const activeSupervisionData = arr.filter(item => item.name.includes('activeSupervision'));
        const activeSupervision = this.processData(activeSupervisionData);
        const praiseUseData = arr.filter(item => item.name.includes('praiseUse'));
        const praiseUse = this.processData(praiseUseData);
        const reprimandsUseData = arr.filter(item => item.name.includes('reprimandsUse'));
        const reprimandsUse = this.processData(reprimandsUseData);
        const positiveNegativeRatioData = arr.filter(item =>
            item.name.includes('positiveNegativeRatio')
        );
        const positiveNegativeRatio = this.processData(positiveNegativeRatioData);
        const reinforcementUseData = arr.filter(item => item.name.includes('reinforcementUse'));
        const reinforcementUse = this.processData(reinforcementUseData);
        const otherData = arr.filter(item => item.name.includes('other'));
        const other = this.processData(otherData);

        return [
            { behavioralExpectations },
            { activeSupervision },
            { praiseUse },
            { reprimandsUse },
            { positiveNegativeRatio },
            { reinforcementUse },
            { other },
        ];
    };

    processData = array => {
        const mappedData = array.map(item => {
            if (item.value) {
                const cleanValue = item.value.replace(/^\s*\n/gm, '');

                return cleanValue.split('\n').filter(value => value !== '');
            }
            return [];
        });
        return mappedData;
    };

    render() {
        return (
            <Loader loading={this.state.loading} removeChildren>
                {this.state.data && <Form onSubmit={this.onSubmit} data={this.state.data} />}
            </Loader>
        );
    }
}
