import React from 'react';
import { Formik, Form, Field, CccisdTextarea } from 'cccisd-formik';
import PropTypes from 'prop-types';
import style from './style.css';
import Save from 'cccisd-icons/folder-upload2';
import Triangle from 'cccisd-icons/triangle2';
export default class Component extends React.Component {
    static propTypes = {
        onSubmit: PropTypes.func,
        data: PropTypes.object,
    };

    state = {
        data: this.props.data.length > 0 ? JSON.parse(this.props.data) : [],
        show: {
            physicalLayout: false,
            classroomRules: false,
            classroomRoutines: false,
            smoothTransitions: false,
            other: false,
        },
    };

    getInitialValues = (question, index) => {
        if (this.state.data) {
            const data = this.state.data;
            const entry = data.find(item => {
                if (question in item) {
                    return item;
                }
                return null;
            });
            if (entry) {
                const values = entry[question];
                if (values[index].length > 0) {
                    let string = '';
                    values[index].forEach(item => {
                        string = string + item + '\n';
                    });

                    return string;
                }
            }
            return '';
        }
    };

    render() {
        return (
            <div className={style.classroomStructure}>
                <div>
                    Enter and edit the list of descriptors for each score for each scale item.
                    Separate descriptors by using a new line.
                </div>
                <br />
                <Formik
                    initialValues={{
                        physicalLayout0: this.getInitialValues('physicalLayout', 0),
                        physicalLayout1: this.getInitialValues('physicalLayout', 1),
                        physicalLayout2: this.getInitialValues('physicalLayout', 2),
                        physicalLayout3: this.getInitialValues('physicalLayout', 3),
                        physicalLayout4: this.getInitialValues('physicalLayout', 4),
                        physicalLayout5: this.getInitialValues('physicalLayout', 5),
                        classroomRules0: this.getInitialValues('classroomRules', 0),
                        classroomRules1: this.getInitialValues('classroomRules', 1),
                        classroomRules2: this.getInitialValues('classroomRules', 2),
                        classroomRules3: this.getInitialValues('classroomRules', 3),
                        classroomRules4: this.getInitialValues('classroomRules', 4),
                        classroomRules5: this.getInitialValues('classroomRules', 5),
                        classroomRoutines0: this.getInitialValues('classroomRoutines', 0),
                        classroomRoutines1: this.getInitialValues('classroomRoutines', 1),
                        classroomRoutines2: this.getInitialValues('classroomRoutines', 2),
                        classroomRoutines3: this.getInitialValues('classroomRoutines', 3),
                        classroomRoutines4: this.getInitialValues('classroomRoutines', 4),
                        classroomRoutines5: this.getInitialValues('classroomRoutines', 5),
                        smoothTransitions0: this.getInitialValues('smoothTransitions', 0),
                        smoothTransitions1: this.getInitialValues('smoothTransitions', 1),
                        smoothTransitions2: this.getInitialValues('smoothTransitions', 2),
                        smoothTransitions3: this.getInitialValues('smoothTransitions', 3),
                        smoothTransitions4: this.getInitialValues('smoothTransitions', 4),
                        smoothTransitions5: this.getInitialValues('smoothTransitions', 5),
                        other0: this.getInitialValues('other', 0),
                        other1: this.getInitialValues('other', 1),
                        other2: this.getInitialValues('other', 2),
                        other3: this.getInitialValues('other', 3),
                        other4: this.getInitialValues('other', 4),
                        other5: this.getInitialValues('other', 5),
                    }}
                    onSubmit={this.props.onSubmit}
                    render={() => (
                        <Form>
                            <div className={style.sectionWrapper}>
                                <span
                                    className={style.dropDownTop}
                                    onClick={() => {
                                        this.setState({
                                            show: {
                                                physicalLayout: !this.state.show.physicalLayout,
                                            },
                                        });
                                    }}
                                >
                                    <h4>Physical Layout</h4>{' '}
                                    <span
                                        className={
                                            this.state.show.physicalLayout
                                                ? style.open
                                                : style.closed
                                        }
                                    >
                                        <Triangle />
                                    </span>
                                </span>
                                {this.state.show.physicalLayout && (
                                    <div>
                                        <Field
                                            name="physicalLayout0"
                                            component={CccisdTextarea}
                                            label="Score: 0 (Needs Attention)"
                                        />
                                        <Field
                                            name="physicalLayout1"
                                            component={CccisdTextarea}
                                            label="Score: 1"
                                        />
                                        <Field
                                            name="physicalLayout2"
                                            component={CccisdTextarea}
                                            label="Score: 2"
                                        />
                                        <Field
                                            name="physicalLayout3"
                                            component={CccisdTextarea}
                                            label="Score: 3"
                                        />
                                        <Field
                                            name="physicalLayout4"
                                            component={CccisdTextarea}
                                            label="Score: 4"
                                        />
                                        <Field
                                            name="physicalLayout5"
                                            component={CccisdTextarea}
                                            label="Score: 5"
                                        />
                                    </div>
                                )}
                            </div>
                            <div className={style.sectionWrapper}>
                                <span
                                    className={style.dropDownTop}
                                    onClick={() => {
                                        this.setState({
                                            show: {
                                                classroomRules: !this.state.show.classroomRules,
                                            },
                                        });
                                    }}
                                >
                                    <h4>Classroom Rules</h4>{' '}
                                    <span
                                        className={
                                            this.state.show.classroomRules
                                                ? style.open
                                                : style.closed
                                        }
                                    >
                                        <Triangle />
                                    </span>
                                </span>
                                {this.state.show.classroomRules && (
                                    <div>
                                        <Field
                                            name="classroomRules0"
                                            component={CccisdTextarea}
                                            label="Score: 0"
                                        />
                                        <Field
                                            name="classroomRules1"
                                            component={CccisdTextarea}
                                            label="Score: 1"
                                        />
                                        <Field
                                            name="classroomRules2"
                                            component={CccisdTextarea}
                                            label="Score: 2"
                                        />
                                        <Field
                                            name="classroomRules3"
                                            component={CccisdTextarea}
                                            label="Score: 3"
                                        />
                                        <Field
                                            name="classroomRules4"
                                            component={CccisdTextarea}
                                            label="Score: 4"
                                        />
                                        <Field
                                            name="classroomRules5"
                                            component={CccisdTextarea}
                                            label="Score: 5"
                                        />
                                    </div>
                                )}
                            </div>
                            <div className={style.sectionWrapper}>
                                <span
                                    className={style.dropDownTop}
                                    onClick={() => {
                                        this.setState({
                                            show: {
                                                classroomRoutines: !this.state.show
                                                    .classroomRoutines,
                                            },
                                        });
                                    }}
                                >
                                    <h4>Classroom Routines</h4>{' '}
                                    <span
                                        className={
                                            this.state.show.classroomRoutines
                                                ? style.open
                                                : style.closed
                                        }
                                    >
                                        <Triangle />
                                    </span>
                                </span>
                                {this.state.show.classroomRoutines && (
                                    <div>
                                        <Field
                                            name="classroomRoutines0"
                                            component={CccisdTextarea}
                                            label="Score: 0"
                                        />
                                        <Field
                                            name="classroomRoutines1"
                                            component={CccisdTextarea}
                                            label="Score: 1"
                                        />
                                        <Field
                                            name="classroomRoutines2"
                                            component={CccisdTextarea}
                                            label="Score: 2"
                                        />
                                        <Field
                                            name="classroomRoutines3"
                                            component={CccisdTextarea}
                                            label="Score: 3"
                                        />
                                        <Field
                                            name="classroomRoutines4"
                                            component={CccisdTextarea}
                                            label="Score: 4"
                                        />
                                        <Field
                                            name="classroomRoutines5"
                                            component={CccisdTextarea}
                                            label="Score: 5"
                                        />
                                    </div>
                                )}
                            </div>
                            <div className={style.sectionWrapper}>
                                <span
                                    className={style.dropDownTop}
                                    onClick={() => {
                                        this.setState({
                                            show: {
                                                smoothTransitions: !this.state.show
                                                    .smoothTransitions,
                                            },
                                        });
                                    }}
                                >
                                    <h4>Smooth Transitions</h4>{' '}
                                    <span
                                        className={
                                            this.state.show.smoothTransitions
                                                ? style.open
                                                : style.closed
                                        }
                                    >
                                        <Triangle />
                                    </span>
                                </span>
                                {this.state.show.smoothTransitions && (
                                    <div>
                                        <Field
                                            name="smoothTransitions0"
                                            component={CccisdTextarea}
                                            label="Score: 0"
                                        />
                                        <Field
                                            name="smoothTransitions1"
                                            component={CccisdTextarea}
                                            label="Score: 1"
                                        />
                                        <Field
                                            name="smoothTransitions2"
                                            component={CccisdTextarea}
                                            label="Score: 2"
                                        />
                                        <Field
                                            name="smoothTransitions3"
                                            component={CccisdTextarea}
                                            label="Score: 3"
                                        />
                                        <Field
                                            name="smoothTransitions4"
                                            component={CccisdTextarea}
                                            label="Score: 4"
                                        />
                                        <Field
                                            name="smoothTransitions5"
                                            component={CccisdTextarea}
                                            label="Score: 5"
                                        />
                                    </div>
                                )}
                            </div>
                            <div className={style.sectionWrapper}>
                                <span
                                    className={style.dropDownTop}
                                    onClick={() => {
                                        this.setState({
                                            show: {
                                                other: !this.state.show.other,
                                            },
                                        });
                                    }}
                                >
                                    <h4>Other</h4>{' '}
                                    <span
                                        className={
                                            this.state.show.other ? style.open : style.closed
                                        }
                                    >
                                        <Triangle />
                                    </span>
                                </span>
                                {this.state.show.other && (
                                    <div>
                                        <Field
                                            name="other0"
                                            component={CccisdTextarea}
                                            label="Score: 0"
                                        />
                                        <Field
                                            name="other1"
                                            component={CccisdTextarea}
                                            label="Score: 1"
                                        />
                                        <Field
                                            name="other2"
                                            component={CccisdTextarea}
                                            label="Score: 2"
                                        />
                                        <Field
                                            name="other3"
                                            component={CccisdTextarea}
                                            label="Score: 3"
                                        />
                                        <Field
                                            name="other4"
                                            component={CccisdTextarea}
                                            label="Score: 4"
                                        />
                                        <Field
                                            name="other5"
                                            component={CccisdTextarea}
                                            label="Score: 5"
                                        />
                                    </div>
                                )}
                            </div>

                            <button
                                className={`btn btn-primary ${style.stickyButton}`}
                                type="submit"
                            >
                                <span style={{ marginRight: '5px' }}>
                                    <Save />
                                </span>
                                Save Values
                            </button>
                        </Form>
                    )}
                />
            </div>
        );
    }
}
