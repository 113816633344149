import React from 'react';
import Tabs from './Tabs';
import ClassroomStructure from './ClassroomStructure';
import InstructionManagement from './InstructionManagement';
import BehaviorManagement from './BehaviorManagement';
import ClassroomClimate from './ClassroomClimate';
import MessagingDates from './MessagingDates';
import TeacherNudges from './TeacherNudges';

const tabList = [
    { name: 'messagingDates', title: 'Messaging Dates', content: <MessagingDates /> },
    { name: 'teacherNudges', title: 'Teacher Nudges', content: <TeacherNudges /> },
    { name: 'classroomStructure', title: 'Classroom Structure', content: <ClassroomStructure /> },
    {
        name: 'instructionManagement',
        title: 'Instruction Management',
        content: <InstructionManagement />,
    },
    { name: 'behaviorManagement', title: 'Behavior Management', content: <BehaviorManagement /> },
    { name: 'classroomClimate', title: 'Classroom Climate', content: <ClassroomClimate /> },
];

// tabs load and make query
export default class EditSiteContent extends React.Component {
    componentDidMount = () => {
        window.addEventListener('beforeunload', this.handleUnload);
    };

    handleUnload = event => {
        const message = 'You have unsaved changes';
        event.returnValue = message;
        return message;
    };

    render() {
        return (
            <div className="container">
                <h1>Edit Site Content</h1>
                <Tabs tabList={tabList} saveInHash />
            </div>
        );
    }
}
