import React from 'react';
import { Formik, Form, Field, CccisdTextarea } from 'cccisd-formik';
import PropTypes from 'prop-types';
import style from './style.css';
import Save from 'cccisd-icons/folder-upload2';
import Triangle from 'cccisd-icons/triangle2';
export default class Component extends React.Component {
    static propTypes = {
        onSubmit: PropTypes.func,
        data: PropTypes.object,
    };

    state = {
        data: this.props.data.length > 0 ? JSON.parse(this.props.data) : [],
        show: {
            schedulePostedFollowed: false,
            academicObjectives: false,
            pacingInstruction: false,
            studentAccuracy: false,
            studentEngagement: false,
            other: false,
        },
    };

    getInitialValues = (question, index) => {
        if (this.state.data) {
            const data = this.state.data;
            const entry = data.find(item => {
                if (question in item) {
                    return item;
                }
                return null;
            });
            if (entry) {
                const values = entry[question];
                if (values[index].length > 0) {
                    let string = '';
                    values[index].forEach(item => {
                        string = string + item + '\n';
                    });

                    return string;
                }
            }
            return '';
        }
    };

    render() {
        return (
            <div>
                <div>
                    Enter and edit the list of descriptors for each score for each scale item.
                    Separate descriptors by using a new line.
                </div>
                <br />
                <Formik
                    initialValues={{
                        schedulePostedFollowed0: this.getInitialValues('schedulePostedFollowed', 0),
                        schedulePostedFollowed1: this.getInitialValues('schedulePostedFollowed', 1),
                        schedulePostedFollowed2: this.getInitialValues('schedulePostedFollowed', 2),
                        schedulePostedFollowed3: this.getInitialValues('schedulePostedFollowed', 3),
                        schedulePostedFollowed4: this.getInitialValues('schedulePostedFollowed', 4),
                        schedulePostedFollowed5: this.getInitialValues('schedulePostedFollowed', 5),
                        academicObjectives0: this.getInitialValues('academicObjectives', 0),
                        academicObjectives1: this.getInitialValues('academicObjectives', 1),
                        academicObjectives2: this.getInitialValues('academicObjectives', 2),
                        academicObjectives3: this.getInitialValues('academicObjectives', 3),
                        academicObjectives4: this.getInitialValues('academicObjectives', 4),
                        academicObjectives5: this.getInitialValues('academicObjectives', 5),
                        pacingInstruction0: this.getInitialValues('pacingInstruction', 0),
                        pacingInstruction1: this.getInitialValues('pacingInstruction', 1),
                        pacingInstruction2: this.getInitialValues('pacingInstruction', 2),
                        pacingInstruction3: this.getInitialValues('pacingInstruction', 3),
                        pacingInstruction4: this.getInitialValues('pacingInstruction', 4),
                        pacingInstruction5: this.getInitialValues('pacingInstruction', 5),
                        studentAccuracy0: this.getInitialValues('studentAccuracy', 0),
                        studentAccuracy1: this.getInitialValues('studentAccuracy', 1),
                        studentAccuracy2: this.getInitialValues('studentAccuracy', 2),
                        studentAccuracy3: this.getInitialValues('studentAccuracy', 3),
                        studentAccuracy4: this.getInitialValues('studentAccuracy', 4),
                        studentAccuracy5: this.getInitialValues('studentAccuracy', 5),
                        studentEngagement0: this.getInitialValues('studentEngagement', 0),
                        studentEngagement1: this.getInitialValues('studentEngagement', 1),
                        studentEngagement2: this.getInitialValues('studentEngagement', 2),
                        studentEngagement3: this.getInitialValues('studentEngagement', 3),
                        studentEngagement4: this.getInitialValues('studentEngagement', 4),
                        studentEngagement5: this.getInitialValues('studentEngagement', 5),
                        other0: this.getInitialValues('other', 0),
                        other1: this.getInitialValues('other', 1),
                        other2: this.getInitialValues('other', 2),
                        other3: this.getInitialValues('other', 3),
                        other4: this.getInitialValues('other', 4),
                        other5: this.getInitialValues('other', 5),
                    }}
                    onSubmit={this.props.onSubmit}
                    render={() => (
                        <Form>
                            <div className={style.sectionWrapper}>
                                <span
                                    className={style.dropDownTop}
                                    onClick={() => {
                                        this.setState({
                                            show: {
                                                schedulePostedFollowed: !this.state.show
                                                    .schedulePostedFollowed,
                                            },
                                        });
                                    }}
                                >
                                    <h4>Schedule Posted and Followed</h4>{' '}
                                    <span
                                        className={
                                            this.state.show.schedulePostedFollowed
                                                ? style.open
                                                : style.closed
                                        }
                                    >
                                        <Triangle />
                                    </span>
                                </span>
                                {this.state.show.schedulePostedFollowed && (
                                    <div>
                                        <Field
                                            name="schedulePostedFollowed0"
                                            component={CccisdTextarea}
                                            label="Score: 0"
                                        />
                                        <Field
                                            name="schedulePostedFollowed1"
                                            component={CccisdTextarea}
                                            label="Score: 1"
                                        />
                                        <Field
                                            name="schedulePostedFollowed2"
                                            component={CccisdTextarea}
                                            label="Score: 2"
                                        />
                                        <Field
                                            name="schedulePostedFollowed3"
                                            component={CccisdTextarea}
                                            label="Score: 3"
                                        />
                                        <Field
                                            name="schedulePostedFollowed4"
                                            component={CccisdTextarea}
                                            label="Score: 4"
                                        />
                                        <Field
                                            name="schedulePostedFollowed5"
                                            component={CccisdTextarea}
                                            label="Score: 5"
                                        />{' '}
                                    </div>
                                )}
                            </div>
                            <div className={style.sectionWrapper}>
                                <span
                                    className={style.dropDownTop}
                                    onClick={() => {
                                        this.setState({
                                            show: {
                                                academicObjectives: !this.state.show
                                                    .academicObjectives,
                                            },
                                        });
                                    }}
                                >
                                    <h4>Academic Objectives</h4>{' '}
                                    <span
                                        className={
                                            this.state.show.academicObjectives
                                                ? style.open
                                                : style.closed
                                        }
                                    >
                                        <Triangle />
                                    </span>
                                </span>
                                {this.state.show.academicObjectives && (
                                    <div>
                                        <Field
                                            name="academicObjectives0"
                                            component={CccisdTextarea}
                                            label="Score: 0"
                                        />
                                        <Field
                                            name="academicObjectives1"
                                            component={CccisdTextarea}
                                            label="Score: 1"
                                        />
                                        <Field
                                            name="academicObjectives2"
                                            component={CccisdTextarea}
                                            label="Score: 2"
                                        />
                                        <Field
                                            name="academicObjectives3"
                                            component={CccisdTextarea}
                                            label="Score: 3"
                                        />
                                        <Field
                                            name="academicObjectives4"
                                            component={CccisdTextarea}
                                            label="Score: 4"
                                        />
                                        <Field
                                            name="academicObjectives5"
                                            component={CccisdTextarea}
                                            label="Score: 5"
                                        />{' '}
                                    </div>
                                )}
                            </div>
                            <div className={style.sectionWrapper}>
                                <span
                                    className={style.dropDownTop}
                                    onClick={() => {
                                        this.setState({
                                            show: {
                                                pacingInstruction: !this.state.show
                                                    .pacingInstruction,
                                            },
                                        });
                                    }}
                                >
                                    <h4>Pacing of Instruction</h4>{' '}
                                    <span
                                        className={
                                            this.state.show.pacingInstruction
                                                ? style.open
                                                : style.closed
                                        }
                                    >
                                        <Triangle />
                                    </span>
                                </span>
                                {this.state.show.pacingInstruction && (
                                    <div>
                                        <Field
                                            name="pacingInstruction0"
                                            component={CccisdTextarea}
                                            label="Score: 0"
                                        />
                                        <Field
                                            name="pacingInstruction1"
                                            component={CccisdTextarea}
                                            label="Score: 1"
                                        />
                                        <Field
                                            name="pacingInstruction2"
                                            component={CccisdTextarea}
                                            label="Score: 2"
                                        />
                                        <Field
                                            name="pacingInstruction3"
                                            component={CccisdTextarea}
                                            label="Score: 3"
                                        />
                                        <Field
                                            name="pacingInstruction4"
                                            component={CccisdTextarea}
                                            label="Score: 4"
                                        />
                                        <Field
                                            name="pacingInstruction5"
                                            component={CccisdTextarea}
                                            label="Score: 5"
                                        />{' '}
                                    </div>
                                )}
                            </div>
                            <div className={style.sectionWrapper}>
                                <span
                                    className={style.dropDownTop}
                                    onClick={() => {
                                        this.setState({
                                            show: {
                                                studentAccuracy: !this.state.show.studentAccuracy,
                                            },
                                        });
                                    }}
                                >
                                    <h4>Student Accuracy</h4>{' '}
                                    <span
                                        className={
                                            this.state.show.studentAccuracy
                                                ? style.open
                                                : style.closed
                                        }
                                    >
                                        <Triangle />
                                    </span>
                                </span>
                                {this.state.show.studentAccuracy && (
                                    <div>
                                        <Field
                                            name="studentAccuracy0"
                                            component={CccisdTextarea}
                                            label="Score: 0"
                                        />
                                        <Field
                                            name="studentAccuracy1"
                                            component={CccisdTextarea}
                                            label="Score: 1"
                                        />
                                        <Field
                                            name="studentAccuracy2"
                                            component={CccisdTextarea}
                                            label="Score: 2"
                                        />
                                        <Field
                                            name="studentAccuracy3"
                                            component={CccisdTextarea}
                                            label="Score: 3"
                                        />
                                        <Field
                                            name="studentAccuracy4"
                                            component={CccisdTextarea}
                                            label="Score: 4"
                                        />
                                        <Field
                                            name="studentAccuracy5"
                                            component={CccisdTextarea}
                                            label="Score: 5"
                                        />{' '}
                                    </div>
                                )}
                            </div>
                            <div className={style.sectionWrapper}>
                                <span
                                    className={style.dropDownTop}
                                    onClick={() => {
                                        this.setState({
                                            show: {
                                                studentEngagement: !this.state.show
                                                    .studentEngagement,
                                            },
                                        });
                                    }}
                                >
                                    <h4>Student Engagement</h4>{' '}
                                    <span
                                        className={
                                            this.state.show.studentEngagement
                                                ? style.open
                                                : style.closed
                                        }
                                    >
                                        <Triangle />
                                    </span>
                                </span>
                                {this.state.show.studentEngagement && (
                                    <div>
                                        <Field
                                            name="studentEngagement0"
                                            component={CccisdTextarea}
                                            label="Score: 0"
                                        />
                                        <Field
                                            name="studentEngagement1"
                                            component={CccisdTextarea}
                                            label="Score: 1"
                                        />
                                        <Field
                                            name="studentEngagement2"
                                            component={CccisdTextarea}
                                            label="Score: 2"
                                        />
                                        <Field
                                            name="studentEngagement3"
                                            component={CccisdTextarea}
                                            label="Score: 3"
                                        />
                                        <Field
                                            name="studentEngagement4"
                                            component={CccisdTextarea}
                                            label="Score: 4"
                                        />
                                        <Field
                                            name="studentEngagement5"
                                            component={CccisdTextarea}
                                            label="Score: 5"
                                        />{' '}
                                    </div>
                                )}
                            </div>
                            <div className={style.sectionWrapper}>
                                <span
                                    className={style.dropDownTop}
                                    onClick={() => {
                                        this.setState({
                                            show: {
                                                other: !this.state.show.other,
                                            },
                                        });
                                    }}
                                >
                                    <h4>Other</h4>{' '}
                                    <span
                                        className={
                                            this.state.show.other ? style.open : style.closed
                                        }
                                    >
                                        <Triangle />
                                    </span>
                                </span>
                                {this.state.show.other && (
                                    <div>
                                        <Field
                                            name="other0"
                                            component={CccisdTextarea}
                                            label="Score: 0"
                                        />
                                        <Field
                                            name="other1"
                                            component={CccisdTextarea}
                                            label="Score: 1"
                                        />
                                        <Field
                                            name="other2"
                                            component={CccisdTextarea}
                                            label="Score: 2"
                                        />
                                        <Field
                                            name="other3"
                                            component={CccisdTextarea}
                                            label="Score: 3"
                                        />
                                        <Field
                                            name="other4"
                                            component={CccisdTextarea}
                                            label="Score: 4"
                                        />
                                        <Field
                                            name="other5"
                                            component={CccisdTextarea}
                                            label="Score: 5"
                                        />
                                    </div>
                                )}
                            </div>

                            <button
                                className={`btn btn-primary ${style.stickyButton}`}
                                type="submit"
                            >
                                <span style={{ marginRight: '5px' }}>
                                    <Save />
                                </span>
                                Save Values
                            </button>
                        </Form>
                    )}
                />
            </div>
        );
    }
}
