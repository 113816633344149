import React from 'react';
// import PropTypes from 'prop-types';
import Form from './form.js';
import Loader from 'cccisd-loader';
import Axios from 'cccisd-axios';
import Notification from 'cccisd-notification';
const Boilerplate = window.cccisd.boilerplate;

export default class Component extends React.Component {
    static propTypes = {
        // params: PropTypes.object,
    };

    state = {
        loading: false,
    };

    componentDidMount = async () => {
        // getDataFrom Query
        // passData down to Form
        const response = await Axios.get(Boilerplate.route('ccu.get.data'));

        if (response) {
            const classroomClimate = response.data.data.classroomClimate;

            this.setState({ data: classroomClimate ? classroomClimate.data : [] });
        }
    };

    onSubmit = async values => {
        let arr = Object.keys(values).map((key, i) => {
            return { name: key, value: values[key] };
        });

        const classroomClimateData = this.extractData(arr);

        const response = await Axios.post(Boilerplate.route('ccu.set.data'), {
            data_key: 'classroomClimate',
            data: classroomClimateData,
        });

        if (response.data.success) {
            Notification({
                message: 'Classroom climate data saved.',
                duration: 3000,
            });
        }
    };

    extractData = arr => {
        const noncontingentAttentionUseData = arr.filter(item =>
            item.name.includes('noncontingentAttentionUse')
        );
        const noncontingentAttentionUse = this.processData(noncontingentAttentionUseData);
        const studentsInteractionsData = arr.filter(item =>
            item.name.includes('studentsInteractions')
        );
        const studentsInteractions = this.processData(studentsInteractionsData);
        const disruptiveBehaviorData = arr.filter(item => item.name.includes('disruptiveBehavior'));
        const disruptiveBehavior = this.processData(disruptiveBehaviorData);

        const otherData = arr.filter(item => item.name.includes('other'));
        const other = this.processData(otherData);

        return [
            { noncontingentAttentionUse },
            { studentsInteractions },
            { disruptiveBehavior },
            { other },
        ];
    };

    processData = array => {
        const mappedData = array.map(item => {
            if (item.value) {
                const cleanValue = item.value.replace(/^\s*\n/gm, '');

                return cleanValue.split('\n').filter(value => value !== '');
            }
            return [];
        });
        return mappedData;
    };

    render() {
        return (
            <Loader loading={this.state.loading} removeChildren>
                {this.state.data && <Form onSubmit={this.onSubmit} data={this.state.data} />}
            </Loader>
        );
    }
}
