import React from 'react';
import { Formik, Form, Field, CccisdTextarea } from 'cccisd-formik';
import PropTypes from 'prop-types';
import style from './style.css';
import Save from 'cccisd-icons/folder-upload2';
import Triangle from 'cccisd-icons/triangle2';
export default class Component extends React.Component {
    static propTypes = {
        onSubmit: PropTypes.func,
        data: PropTypes.object,
    };

    state = {
        data: this.props.data.length > 0 ? JSON.parse(this.props.data) : [],
        show: {
            behavioralExpectations: false,
            activeSupervision: false,
            praiseUse: false,
            reprimandUse: false,
            positiveNegativeRatio: false,
            reinforcementUse: false,
            other: false,
        },
    };

    getInitialValues = (question, index) => {
        if (this.state.data) {
            const data = this.state.data;
            const entry = data.find(item => {
                if (question in item) {
                    return item;
                }
                return null;
            });
            if (entry) {
                const values = entry[question];
                if (values[index].length > 0) {
                    let string = '';
                    values[index].forEach(item => {
                        string = string + item + '\n';
                    });

                    return string;
                }
            }
            return '';
        }
    };

    render() {
        return (
            <div className={style.classroomStructure}>
                <div>
                    Enter and edit the list of descriptors for each score for each scale item.
                    Separate descriptors by using a new line.
                </div>
                <br />
                <Formik
                    initialValues={{
                        behavioralExpectations0: this.getInitialValues('behavioralExpectations', 0),
                        behavioralExpectations1: this.getInitialValues('behavioralExpectations', 1),
                        behavioralExpectations2: this.getInitialValues('behavioralExpectations', 2),
                        behavioralExpectations3: this.getInitialValues('behavioralExpectations', 3),
                        behavioralExpectations4: this.getInitialValues('behavioralExpectations', 4),
                        behavioralExpectations5: this.getInitialValues('behavioralExpectations', 5),
                        activeSupervision0: this.getInitialValues('activeSupervision', 0),
                        activeSupervision1: this.getInitialValues('activeSupervision', 1),
                        activeSupervision2: this.getInitialValues('activeSupervision', 2),
                        activeSupervision3: this.getInitialValues('activeSupervision', 3),
                        activeSupervision4: this.getInitialValues('activeSupervision', 4),
                        activeSupervision5: this.getInitialValues('activeSupervision', 5),
                        praiseUse0: this.getInitialValues('praiseUse', 0),
                        praiseUse1: this.getInitialValues('praiseUse', 1),
                        praiseUse2: this.getInitialValues('praiseUse', 2),
                        praiseUse3: this.getInitialValues('praiseUse', 3),
                        praiseUse4: this.getInitialValues('praiseUse', 4),
                        praiseUse5: this.getInitialValues('praiseUse', 5),
                        reprimandsUse0: this.getInitialValues('reprimandUse', 0),
                        reprimandsUse1: this.getInitialValues('reprimandUse', 1),
                        reprimandsUse2: this.getInitialValues('reprimandUse', 2),
                        reprimandsUse3: this.getInitialValues('reprimandUse', 3),
                        reprimandsUse4: this.getInitialValues('reprimandUse', 4),
                        reprimandsUse5: this.getInitialValues('reprimandUse', 5),
                        positiveNegativeRatio0: this.getInitialValues('positiveNegativeRatio', 0),
                        positiveNegativeRatio1: this.getInitialValues('positiveNegativeRatio', 1),
                        positiveNegativeRatio2: this.getInitialValues('positiveNegativeRatio', 2),
                        positiveNegativeRatio3: this.getInitialValues('positiveNegativeRatio', 3),
                        positiveNegativeRatio4: this.getInitialValues('positiveNegativeRatio', 4),
                        positiveNegativeRatio5: this.getInitialValues('positiveNegativeRatio', 5),
                        reinforcementUse0: this.getInitialValues('reinforcementUse', 0),
                        reinforcementUse1: this.getInitialValues('reinforcementUse', 1),
                        reinforcementUse2: this.getInitialValues('reinforcementUse', 2),
                        reinforcementUse3: this.getInitialValues('reinforcementUse', 3),
                        reinforcementUse4: this.getInitialValues('reinforcementUse', 4),
                        reinforcementUse5: this.getInitialValues('reinforcementUse', 5),
                        other0: this.getInitialValues('other', 0),
                        other1: this.getInitialValues('other', 1),
                        other2: this.getInitialValues('other', 2),
                        other3: this.getInitialValues('other', 3),
                        other4: this.getInitialValues('other', 4),
                        other5: this.getInitialValues('other', 5),
                    }}
                    onSubmit={this.props.onSubmit}
                    render={() => (
                        <Form>
                            <div className={style.sectionWrapper}>
                                <span
                                    className={style.dropDownTop}
                                    onClick={() => {
                                        this.setState({
                                            show: {
                                                behavioralExpectations: !this.state.show
                                                    .behavioralExpectations,
                                            },
                                        });
                                    }}
                                >
                                    <h4>Behavioral Expectations</h4>{' '}
                                    <span
                                        className={
                                            this.state.show.behavioralExpectations
                                                ? style.open
                                                : style.closed
                                        }
                                    >
                                        <Triangle />
                                    </span>
                                </span>
                                {this.state.show.behavioralExpectations && (
                                    <div>
                                        <Field
                                            name="behavioralExpectations0"
                                            component={CccisdTextarea}
                                            label="Score: 0"
                                        />
                                        <Field
                                            name="behavioralExpectations1"
                                            component={CccisdTextarea}
                                            label="Score: 1"
                                        />
                                        <Field
                                            name="behavioralExpectations2"
                                            component={CccisdTextarea}
                                            label="Score: 2"
                                        />
                                        <Field
                                            name="behavioralExpectations3"
                                            component={CccisdTextarea}
                                            label="Score: 3"
                                        />
                                        <Field
                                            name="behavioralExpectations4"
                                            component={CccisdTextarea}
                                            label="Score: 4"
                                        />
                                        <Field
                                            name="behavioralExpectations5"
                                            component={CccisdTextarea}
                                            label="Score: 5"
                                        />{' '}
                                    </div>
                                )}
                            </div>
                            <div className={style.sectionWrapper}>
                                <span
                                    className={style.dropDownTop}
                                    onClick={() => {
                                        this.setState({
                                            show: {
                                                activeSupervision: !this.state.show
                                                    .activeSupervision,
                                            },
                                        });
                                    }}
                                >
                                    <h4>Active Supervision</h4>{' '}
                                    <span
                                        className={
                                            this.state.show.activeSupervision
                                                ? style.open
                                                : style.closed
                                        }
                                    >
                                        <Triangle />
                                    </span>
                                </span>
                                {this.state.show.activeSupervision && (
                                    <div>
                                        <Field
                                            name="activeSupervision0"
                                            component={CccisdTextarea}
                                            label="Score: 0"
                                        />
                                        <Field
                                            name="activeSupervision1"
                                            component={CccisdTextarea}
                                            label="Score: 1"
                                        />
                                        <Field
                                            name="activeSupervision2"
                                            component={CccisdTextarea}
                                            label="Score: 2"
                                        />
                                        <Field
                                            name="activeSupervision3"
                                            component={CccisdTextarea}
                                            label="Score: 3"
                                        />
                                        <Field
                                            name="activeSupervision4"
                                            component={CccisdTextarea}
                                            label="Score: 4"
                                        />
                                        <Field
                                            name="activeSupervision5"
                                            component={CccisdTextarea}
                                            label="Score: 5"
                                        />
                                    </div>
                                )}
                            </div>
                            <div className={style.sectionWrapper}>
                                <span
                                    className={style.dropDownTop}
                                    onClick={() => {
                                        this.setState({
                                            show: {
                                                praiseUse: !this.state.show.praiseUse,
                                            },
                                        });
                                    }}
                                >
                                    <h4>Use of Praise</h4>{' '}
                                    <span
                                        className={
                                            this.state.show.praiseUse ? style.open : style.closed
                                        }
                                    >
                                        <Triangle />
                                    </span>
                                </span>
                                {this.state.show.praiseUse && (
                                    <div>
                                        <Field
                                            name="praiseUse0"
                                            component={CccisdTextarea}
                                            label="Score: 0"
                                        />
                                        <Field
                                            name="praiseUse1"
                                            component={CccisdTextarea}
                                            label="Score: 1"
                                        />
                                        <Field
                                            name="praiseUse2"
                                            component={CccisdTextarea}
                                            label="Score: 2"
                                        />
                                        <Field
                                            name="praiseUse3"
                                            component={CccisdTextarea}
                                            label="Score: 3"
                                        />
                                        <Field
                                            name="praiseUse4"
                                            component={CccisdTextarea}
                                            label="Score: 4"
                                        />
                                        <Field
                                            name="praiseUse5"
                                            component={CccisdTextarea}
                                            label="Score: 5"
                                        />{' '}
                                    </div>
                                )}
                            </div>
                            <div className={style.sectionWrapper}>
                                <span
                                    className={style.dropDownTop}
                                    onClick={() => {
                                        this.setState({
                                            show: {
                                                reprimandsUse: !this.state.show.reprimandsUse,
                                            },
                                        });
                                    }}
                                >
                                    <h4>Use of Reprimands</h4>{' '}
                                    <span
                                        className={
                                            this.state.show.reprimandsUse
                                                ? style.open
                                                : style.closed
                                        }
                                    >
                                        <Triangle />
                                    </span>
                                </span>
                                {this.state.show.reprimandsUse && (
                                    <div>
                                        <Field
                                            name="reprimandsUse0"
                                            component={CccisdTextarea}
                                            label="Score: 0"
                                        />
                                        <Field
                                            name="reprimandsUse1"
                                            component={CccisdTextarea}
                                            label="Score: 1"
                                        />
                                        <Field
                                            name="reprimandsUse2"
                                            component={CccisdTextarea}
                                            label="Score: 2"
                                        />
                                        <Field
                                            name="reprimandsUse3"
                                            component={CccisdTextarea}
                                            label="Score: 3"
                                        />
                                        <Field
                                            name="reprimandsUse4"
                                            component={CccisdTextarea}
                                            label="Score: 4"
                                        />
                                        <Field
                                            name="reprimandsUse5"
                                            component={CccisdTextarea}
                                            label="Score: 5"
                                        />{' '}
                                    </div>
                                )}
                            </div>
                            <div className={style.sectionWrapper}>
                                <span
                                    className={style.dropDownTop}
                                    onClick={() => {
                                        this.setState({
                                            show: {
                                                positiveNegativeRatio: !this.state.show
                                                    .positiveNegativeRatio,
                                            },
                                        });
                                    }}
                                >
                                    <h4>Positive to Negative Ratio</h4>{' '}
                                    <span
                                        className={
                                            this.state.show.positiveNegativeRatio
                                                ? style.open
                                                : style.closed
                                        }
                                    >
                                        <Triangle />
                                    </span>
                                </span>
                                {this.state.show.positiveNegativeRatio && (
                                    <div>
                                        <Field
                                            name="positiveNegativeRatio0"
                                            component={CccisdTextarea}
                                            label="Score: 0"
                                        />
                                        <Field
                                            name="positiveNegativeRatio1"
                                            component={CccisdTextarea}
                                            label="Score: 1"
                                        />
                                        <Field
                                            name="positiveNegativeRatio2"
                                            component={CccisdTextarea}
                                            label="Score: 2"
                                        />
                                        <Field
                                            name="positiveNegativeRatio3"
                                            component={CccisdTextarea}
                                            label="Score: 3"
                                        />
                                        <Field
                                            name="positiveNegativeRatio4"
                                            component={CccisdTextarea}
                                            label="Score: 4"
                                        />
                                        <Field
                                            name="positiveNegativeRatio5"
                                            component={CccisdTextarea}
                                            label="Score: 5"
                                        />{' '}
                                    </div>
                                )}
                            </div>
                            <div className={style.sectionWrapper}>
                                <span
                                    className={style.dropDownTop}
                                    onClick={() => {
                                        this.setState({
                                            show: {
                                                reinforcementUse: !this.state.show.reinforcementUse,
                                            },
                                        });
                                    }}
                                >
                                    <h4>Use of Reinforcement</h4>{' '}
                                    <span
                                        className={
                                            this.state.show.reinforcementUse
                                                ? style.open
                                                : style.closed
                                        }
                                    >
                                        <Triangle />
                                    </span>
                                </span>
                                {this.state.show.reinforcementUse && (
                                    <div>
                                        <Field
                                            name="reinforcementUse0"
                                            component={CccisdTextarea}
                                            label="Score: 0"
                                        />
                                        <Field
                                            name="reinforcementUse1"
                                            component={CccisdTextarea}
                                            label="Score: 1"
                                        />
                                        <Field
                                            name="reinforcementUse2"
                                            component={CccisdTextarea}
                                            label="Score: 2"
                                        />
                                        <Field
                                            name="reinforcementUse3"
                                            component={CccisdTextarea}
                                            label="Score: 3"
                                        />
                                        <Field
                                            name="reinforcementUse4"
                                            component={CccisdTextarea}
                                            label="Score: 4"
                                        />
                                        <Field
                                            name="reinforcementUse5"
                                            component={CccisdTextarea}
                                            label="Score: 5"
                                        />{' '}
                                    </div>
                                )}
                            </div>
                            <div className={style.sectionWrapper}>
                                <span
                                    className={style.dropDownTop}
                                    onClick={() => {
                                        this.setState({
                                            show: {
                                                other: !this.state.show.other,
                                            },
                                        });
                                    }}
                                >
                                    <h4>Other</h4>{' '}
                                    <span
                                        className={
                                            this.state.show.other ? style.open : style.closed
                                        }
                                    >
                                        <Triangle />
                                    </span>
                                </span>
                                {this.state.show.other && (
                                    <div>
                                        <Field
                                            name="other0"
                                            component={CccisdTextarea}
                                            label="Score: 0"
                                        />
                                        <Field
                                            name="other1"
                                            component={CccisdTextarea}
                                            label="Score: 1"
                                        />
                                        <Field
                                            name="other2"
                                            component={CccisdTextarea}
                                            label="Score: 2"
                                        />
                                        <Field
                                            name="other3"
                                            component={CccisdTextarea}
                                            label="Score: 3"
                                        />
                                        <Field
                                            name="other4"
                                            component={CccisdTextarea}
                                            label="Score: 4"
                                        />
                                        <Field
                                            name="other5"
                                            component={CccisdTextarea}
                                            label="Score: 5"
                                        />{' '}
                                    </div>
                                )}
                            </div>

                            <button
                                className={`btn btn-primary ${style.stickyButton}`}
                                type="submit"
                            >
                                <span style={{ marginRight: '5px' }}>
                                    <Save />
                                </span>
                                Save Values
                            </button>
                        </Form>
                    )}
                />
            </div>
        );
    }
}
